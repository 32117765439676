<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-novi-zapis
          variant="success"
          size="sm"
          @click="noviZapis('DodajNoviArtikal')"
        >
          {{ $t('NoviArtikal') }}
        </b-button>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Artikli')">
        <div>
          <b-table
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(id)="data">
              <b-button-group size="sm">
                <b-button
                  variant="info"
                  size="sm"
                  @click="izmeniZapis(data.item.id, 'IzmeniArtikal')"
                >
                  {{ $t("Izmeni") }}
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                >
                  {{ $t("Obriši") }}
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-novi-zapis"
      ref="modal-novi-zapis"
      cancel-variant="outline-secondary"
      :ok-title="$t('Unesi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="modalArtikalTitle"
      @ok="snimiArtikal"
    >
      <b-form>
        <b-form-group>
          <label for="sifra_artikla">{{ $t('SifraArtikla') }}</label>
          <b-form-input
            id="sifra_artikla"
            v-model="dataArtikal.sifra_artikla"
            :placeholder="$t('SifraArtikla')"
          />
          <small
            v-if="formErrors['sifra_artikla']"
            class="text-danger"
          >
            {{ $t(formErrors['sifra_artikla'][0]) }}
          </small>
        </b-form-group>
        <b-form-group>
          <label for="naziv_artikla">{{ $t('NazivArtikla') }}</label>
          <b-form-input
            id="naziv_artikla"
            v-model="dataArtikal.naziv_artikla"
            :placeholder="$t('NazivArtikla')"
          />
          <small
            v-if="formErrors['naziv_artikla']"
            class="text-danger"
          >
            {{ $t(formErrors['naziv_artikla'][0]) }}
          </small>
        </b-form-group>
        <b-form-group>
          <label for="poreska_stopa">{{ $t('PoreskaStopa') }}</label>
          <v-select
            v-model="dataArtikal.poreska_stopa"
            :placeholder="$t('PoreskaStopa')"
            :options="poreskeStope"
            :clearable="false"
            input-id="poreska_stopa"
          />
          <small
            v-if="formErrors['poreska_stopa']"
            class="text-danger"
          >
            {{ $t(formErrors['poreska_stopa'][0]) }}
          </small>
        </b-form-group>
        <b-form-group>
          <label for="cena">{{ $t('Cena') }}</label>
          <b-form-input
            id="cena"
            v-model="dataArtikal.cena"
            :placeholder="$t('Cena')"
            type="number"
          />
          <small
            v-if="formErrors['cena']"
            class="text-danger"
          >
            {{ $t(formErrors['cena'][0]) }}
          </small>
        </b-form-group>
      </b-form>
      <b-form-group
        :label="$t('Opis')"
        label-for="opis"
      >
        <b-form-textarea
          id="opis_artikla"
          v-model="dataArtikal.opis_artikla"
          :placeholder="$t('Opis')"
          rows="3"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCardBody,
  BButtonGroup,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BButtonGroup,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'sifra_artikla', label: this.$i18n.t('SifraArtikla') },
        { key: 'naziv_artikla', label: this.$i18n.t('NazivArtikla') },
        { key: 'cena', label: this.$i18n.t('Cena') },
        { key: 'poreska_stopa', label: this.$i18n.t('PoreskaStopa') },
        { key: 'opis_artikla', label: this.$i18n.t('Opis') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      formErrors: [],
      dataArtikal: {},
      modalArtikalTitle: '',
      notificationVariant: 'success',
      notificationTitle: this.$i18n.t('Uspesno'),
      notificationMessage: this.$i18n.t('NoviZapisJeUspesnoDodat'),
      unosTip: 'noviZapis',
      poreskeStope: ['А', 'Ђ', 'Г', 'Е'],
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    noviZapis(tipZapisa) {
      this.formErrors = []
      this.modalArtikalTitle = this.$i18n.t(tipZapisa)
    },
    izmeniZapis(artikalId, tipZapisa) {
      this.formErrors = []
      this.modalArtikalTitle = this.$i18n.t(tipZapisa)
      this.$http.get(`/autoskole-administratori/sifarnici-artikala/${artikalId}`).then(response => {
        this.dataArtikal = response.data
      })
      this.unosTip = 'izmenaZapisa'
      this.$refs['modal-novi-zapis'].show()
    },
    snimiArtikal() {
      if (this.unosTip === 'noviZapis') {
        this.$http.post('/autoskole-administratori/sifarnici-artikala', this.dataArtikal)
          .then(() => {
            this.dataArtikal = {}
            this.notificationVariant = 'success'
            this.notificationTitle = this.$i18n.t('Uspesno')
            this.notificationMessage = this.$i18n.t('NoviZapisJeUspesnoDodat')
            this.prikaziPoruku()
            this.pokupiListu()
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.notificationVariant = 'danger'
              this.notificationTitle = this.$i18n.t('Neuspesno')
              this.notificationMessage = this.$i18n.t('MolimoPopuniteSvaPolja')
              this.formErrors = error.response.data.errors
              this.$refs['modal-novi-zapis'].show()
              this.prikaziPoruku()
            }
          })
      } else {
        this.$http.put(`/autoskole-administratori/sifarnici-artikala/${this.dataArtikal.id}`, this.dataArtikal)
          .then(() => {
            this.dataArtikal = {}
            this.notificationVariant = 'success'
            this.notificationTitle = this.$i18n.t('Uspesno')
            this.notificationMessage = this.$i18n.t('NoviZapisJeUspesnoDodat')
            this.prikaziPoruku()
            this.pokupiListu()
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.notificationVariant = 'danger'
              this.notificationTitle = this.$i18n.t('Neuspesno')
              this.notificationMessage = this.$i18n.t('MolimoPopuniteSvaPolja')
              this.formErrors = error.response.data.errors
              this.$refs['modal-novi-zapis'].show()
              this.prikaziPoruku()
            }
          })
      }
    },
    pokupiListu() {
      this.$http.get('/autoskole-administratori/sifarnici-artikala').then(response => {
        this.items = response.data
      })
    },
    prikaziPoruku() {
      const variant = this.notificationVariant
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.notificationTitle,
          icon: 'InfoIcon',
          text: this.notificationMessage,
          variant,
        },
      },
      {
        position: 'top-center',
      })
    },
  },
}
</script>
